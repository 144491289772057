@charset 'utf-8';

:root {
    --font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    --title-font-family: Questrial, sans-serif;
}

/* {{{ Desktop */

body {
    font-family: var(--font-family);
    margin: 0;
    overflow-x: hidden;
}

body.white {
    color: black;
    background: white;
}

body.black {
    color: white;
    background: black;
}


footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    margin-bottom: 0px;
    z-index: 400;
}

div.menu-centered {	
    background: rgba(0, 0, 0, .6);
    height: 4rem;
    width: 100%;
}

/* {{{ Nav bar */

ul.menu {
    width: 70%;
    margin: auto;
    height: 100%;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
}

ul.menu li {
    flex: 1;
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 0.15rem;
    margin-bottom: 0;
    display: inline-block;
    height: 100%;
    text-align: center;
    vertical-align: middle;
}

ul.menu a {
    text-decoration: none;
    padding-top: 1.5rem;
    color: white;
    height: 4rem;
    width: 100%;
    display: block;
    transition-property: background-color, color;
    transition-duration: .5s;
    transition-timing-function: ease-out;
}

ul.menu a:hover {
    color: black;
    background: white;
    font-weight: bold;
}

ul.menu .submenu {
    display: none;
    width: 100%;
    position: absolute;
    bottom: 60px;
    left: 0;
    background: white;
    color: black;
    padding-top: 1em;
    padding-bottom: 2em;
    border-top: rgba(0, 0, 0, .6) solid 1px;
}

ul.menu .submenu p {
    height: 2rem;
    margin: 0;
}

ul.menu .submenu a {
    padding-top: 0.8rem;
    color: black;
    height: 100%;
    display: block;
    width: 100%;
    transition-property: background-color, color;
    transition-duration: .2s;
    transition-timing-function: ease-out;
}

ul.menu .submenu a:hover, a:focus {
    color: black;
    background: #eee;
}

ul.menu .submenu span.person-name::after {
    content: " – ";
}

ul.menu .submenu span.person-name, span.show-name {
    letter-spacing: 0.2em;
    font-weight: bold;
}

ul.menu .submenu span.person-role {
    color: #888;
    text-transform: none;
    font-style: italic;
    font-size: 1.2em;
    font-weight: normal;
}

ul.menu li.with-sub:hover .submenu, ul.menu li.with-sub:focus .submenu { 
    display: block;
}

/* }}} */

/* {{{ Company name */
div#company-name {
    position: fixed;
    left: 5rem;
    top: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    width: 10rem;
    line-height: 1.8;
    letter-spacing: 0.2em;
    font-family: var(--title-font-family);
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 300;

}

div#company-name a.menu-button {
    display:none;
}

div#company-name a.name-link {
    text-decoration:none;
}

body.black div#company-name a {
    color: white;
}

body.black div#company-name span {
    display:inline;
    border-bottom: 1px white solid;
    padding-bottom: 1px;
}

body.white div#company-name span {
    display:inline;
    border-bottom: 1px black solid;
    padding-bottom: 1px;
}

body.white div#company-name a {
    color: black;
}

/* }}} */ 

/* Collectif {{{ */

section#collectif > section {
    width:50%;
    margin: auto;
    padding-bottom: 4rem;
}

section#collectif h1.person-name {
    text-transform: uppercase;
    font-weight: 900;
    border-bottom: 2px black solid;
    text-align: center;
    font-family: var(--title-font-family);
    letter-spacing: 1vw;
    font-stretch: ultra-condensed;
    font-size: 2.5em;
    margin-bottom: .5em;
}

section#collectif h2.person-role {
    margin-top: .5em;
    margin-bottom: 1.5em;
    text-transform: uppercase;
    font-family: var(--title-font-family);
    text-align: center;
    letter-spacing: .5vw;
    font-size: 2.3em;
}


section#collectif .info-placeholder .info-row {
    display: block;
    margin-top: 1rem;
    width: 100%;
}

section#collectif .info-placeholder .info-row::after {
    content: "";
    display: block;
    clear: both;
}

section#collectif .info-placeholder .info-cell {
    margin-bottom: 2rem;
    width: 48%;
}

section#collectif .info-placeholder .info-cell button.slick-arrow {
    z-index: 300;
}

section#collectif .info-placeholder .info-cell:first-child {
    float: left;
}

section#collectif .info-placeholder  .info-cell:last-child {
    float: right;
}

section#collectif h3 {
    font-family: var(--title-font-family);
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
    border-bottom: solid 2px black;
    display: inline-block;
    margin: 0;

}

section#collectif iframe {
    width: 100%;
    height: 300px;
}

section#collectif div.contact ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}


section#collectif a {
    color: black;
    font-weight: bold;
}

section#collectif div.contact a {
    text-decoration: none;
}

div.pictures img {
    width: 100%;
}

/* }}} */

/* {{{ blog */

section.blog-post {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4em;
    margin-top: 2em;
}

section.blog-post div.post-title a {
    text-decoration: none;
    color: black;
}

section.blog-post div.post-title a:hover, section.blog-post div.post-title a:focus {
    color: black;
}

section.blog-post div.post-title h1 {
    font-family: var(--title-font-family);
    text-transform: uppercase;
    letter-spacing: 1vw;
    font-stretch: ultra-condensed;
    font-weight: 900;
    text-align: center;
    font-size: 2.5rem;
    border-bottom: 2px solid black;
}

section.blog-post div.nav-links a {
    color: black;
    font-weight: bold;
    text-decoration: underline;
}

section.blog-post div.nav-links a:hover, section.blog-post div.nav-links a:focus {
    text-decoration: underline;
}

section.blog-post  div.post-content {
    padding-top: 2.5em;

}

section.blog-post  div.post-content img, section.blog-post  div.post-content iframe {
    max-width: 100%;
    max-height: 30vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

section.blog-post div.post-footer {
    text-align: right;
    padding-top: 2em;
    font-size: .9rem;
}

section.blog-post div.nav-links {
    margin-top: 3em;
    padding-bottom: 1em;

}

/* }}} */

/* {{{ Common section styles */
section.contact, section.mentions-legales, section.site-conception, section.archives, section.generic {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 6em;
}

section.contact a, section.mentions-legales a, section.site-conception a, section.archives a, section.generic a {
    color: black;
    font-weight: bold;
    text-decoration: none;
}

section.contact a:hover,
section.mentions-legales a:hover,
section.site-conception a:hover,
section.archives a:hover,
section.contact a:hover,
section.mentions-legales a:hover,
section.site-conception a:hover,
section.generic a:hover,
section.archives a:hover {
    color: black;
    font-weight: bold;
    text-decoration: underline;
}

section.contact h1,
section.mentions-legales h1,
section.site-conception h1,
section.archives h1, 
section.generic h1 {
    font-family: var(--title-font-family);
    text-transform: uppercase;
    letter-spacing: .2em;
    font-stretch: ultra-condensed;
    font-weight: 900;
    text-align: center;
    font-size: 2.5rem;
    border-bottom: 2px solid black;
}

section.contact ul,
section.mentions-legales ul,
section.site-conception ul,
section.archives ul {
    list-style: none;
    padding-left: 0;
}

/* }}} */

/* {{{ Contact */
section.site-conception {
    margin-bottom: 5em;
    --input-padding:.5rem;
    --input-margin:.5rem;
}



section.contact input, .basic-input {
    font-family: var(--font-family);
    display: block;
    width: 100%;
    margin-top: var(--input-margin);
    margin-bottom: var(--input-margin);
    height: 2rem;
    font-size: 1rem;
    border: #ddd 1px solid;
    padding-left: var(--input-padding);
    padding-right: var(--input-padding);
    box-sizing: border-box;
}


.basic-button {
    font-size: 1em;
    font-weight: bold;
    color: black;
    background: transparent;
    border: 0 !important;
    width: auto !important;
    text-align: left;
    cursor: pointer;
}

.basic-button:hover, .basic-button:focus {
    font-weight: bold;
    color: black;
    background: transparent;
    text-decoration: underline;
    border: none;
}

section.contact textarea {
    font-family: var(--font-family);
    padding: var(--input-padding);
    margin-top: var(--input-margin);
    margin-bottom: var(--input-margin);
    height: 15em;
    resize: none;
    display: block;
    width: 100%;
    border: #ddd 1px solid;
    box-sizing: border-box;
}

/* }}} */

/* {{{ Spectacle */

section.show-info {
    width: 100%;
    position: fixed;
    top: 5em;
    display: none;

}

section.show-info div.content {
    box-sizing: border-box;
    position: relative;
    width: 40%;
    background: white;
    color: black;
    margin: auto;
    height: 80vh;
    padding-top: 2em;
    padding-bottom: 2em;

}

.boxclose {
    position: absolute;
    top: .2em;
    right: 1em;
    font-weight: bold;
    font-size: 1.5em;
    color: black;
    cursor: pointer;
    text-decoration: none;
    padding: 0;
    border: 0;
}

.boxclose:hover {  
    text-decoration: none;
}

.boxclose::after {  
    content: '×';
}

section.show-info div.content div.inner-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-left: 2em;
    padding-right: 2em;

}

section.show-info div.content div.inner-content img, section.show-info div.content div.inner-content iframe {
    width: 98%;
    display: block;
    margin: auto;
}

section.show-info div.content div.inner-content .caption {
    font-size: .8em;
    width: 100%;
    font-style: italic;
    text-align: center;
    margin-top: -1em;
}

section.show-info h1 {
    margin-top: 2em;
    font-family: var(--title-font-family);
    text-transform: uppercase;
    letter-spacing: 1vw;
    border-bottom: solid 2px black;
    text-align: center;
    font-size: 2rem;
    font-weight: 900;
}


section.show-info table {
    width: 70%;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5em;
}

section.show-info th, section.show-info td {
    border: solid black 1px;
}

section.show-info td {
    padding: 0.3em;
}
section.show-info td p {
    margin: 0;
}

section.show-info td.date {
    text-align: center;
}

/* }}} */

/* {{{ Archives */

#archive-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.archive-item {
    position: relative;
    --size: 200px;
    width: var(--size);
    height: var(--size);
    margin-bottom: 10px;
    box-sizing: border-box;
    margin-right: 1rem;
}

.archive-item:hover {
    cursor: pointer;
}

.archive-item:hover .text {
    opacity: 100%;
    height: auto;
    transition: opacity 1s, height 1s;
}

.archive-item img {
    width: 100%;
    height: 100%;
}

.archive-item .image {
    width: 100%;
    height: 100%;
    opacity: 100%;
}

.archive-item .text {
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: opacity 1s, height 1s;
    background-color: rgba(255, 255, 255, .8);
    color: black;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
}


.archive-item .text h2 {
    font-size: 1em;
    font-weight: normal;
}

.archive-item .text iframe {
    margin-left: auto;
    margin-right: auto;
}

.archive-picture img, .archive-description img {
    max-width: 100%;
    display: block;
    margin-left:auto;
    margin-right:auto;
}

.archive-description iframe {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    display: block;
}

.archive-back {
    margin-top: 2rem;
}
/* {{{ culturecheznous */
section.videos .show img {
    width: 100%;
}

section.videos .show .link-to-video {
    text-align: center;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}
/* }}} */
 


/* }}} */

div.rich-text a {
    color: black;
    font-weight: bold;
    text-decoration: underline;
}

div.rich-text a:hover {
    text-decoration: underline;
}

div.rich-text h2 {
    font-family: var(--title-font-family);
    font-style: italic;
    margin-top: 1.5em;
}

div.button-placeholder {
    position: relative;
    clear: both;
    text-align: center;
    top: 55vh;
    z-index: 1;
}

div.button-placeholder a,
div.button-placeholder a:visited {
    text-align: center;
    padding: 1em;
    background: rgba(0, 0, 0, .6);
    color: white;
    transition-property: background-color, color;
    transition-duration: .5s;
    transition-timing-function: ease-out;
    cursor: pointer;
}

div.button-placeholder a:hover,
div.button-placeholder a:focus {
    text-align: center;
    padding: 1em;
    background: white;
    color: black;
}

div.background-placeholder {
    z-index: 0;
    float: left;
    position: absolute;
    top: 0;
    height:100vh;
    width: 100%;
}

div.background-placeholder img.background {
    z-index: 0;
    margin: auto;
    width: 100%;
    position: absolute;
    top: 0;

}

div.background-placeholder div.bg-show {
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover
}

div.background-placeholder button.slick-arrow {
    z-index: 300;
}

/* }}} */

/* {{{ media queries-mobile */

@media screen and (max-width:900px) {

    /* {{{ Company name */
    div#company-name {
        position: fixed;
        top:0 ;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: .7rem;


    }

    div#company-name span {
        display: inline;
        border-bottom: none;
    }

    div#company-name a.menu-button {
        display: inherit;
        position: fixed;
        right: 1rem;
        border: none;
        text-decoration: none;
        transition: transform .5s;
    }

    div#company-name a.menu-button.active {
        transform: rotate(90deg);
    }

    /* }}} */


    div.background-placeholder img.background {
        top: 50%;
        transform: translateY(-50%);
    }


    body.black div#company-name {
        background: rgba(0,0,0,.8);
        padding: 0.5rem;

    }

    body.black a.name-link {
        border-bottom: 1px white solid;
        text-decoration: none;
    }

    body.black div#company-name span {
        display: inline;
        border-bottom: none;
    }

    body.white  div#company-name {
        background: rgba(255,255,255,.8);
        padding: 0.5rem;
    }

    body.white div#company-name span {
        display: inline;
        border-bottom: none;
    }

    body.white a.name-link {
        border-bottom: 1px black solid;
        text-decoration: none;
    }

    footer {
        position: fixed;
        height: 100%;
        width: 60%;
        background: rgba(0, 0, 0, .8);
        transform: translate(-100%);
        transition: transform .5s;
        z-index: 2000;
    }

    footer ul.menu {
        padding-top: 50%;
        padding-left: 0em;
        width: 100%;
        margin: 0;
        display: block;

    }
    footer ul.menu li {
        display: block; 
        padding-top: 1rem;
        height: 2em;
        text-align: center;
        font-weight: bold;
    }

    footer ul.menu li.with-sub:hover .submenu , footer ul.menu li.with-sub:focus .submenu   { 
        display: none;
    }

    footer ul.menu a {
        padding-top: .5em;
        height: 2em;
    }

    footer ul.menu a:hover {
        background: transparent;
        color: white;
        font-weight: bold;
    }

    footer.shown {
        transform: translate(0);
    }

    /* Show section */

    section.show-info {
        top: 2rem;
        height: calc(100% - 2rem);
        z-index: 500;
    }
    section.show-info div.content {
        padding-top:0;
        width: 100%;
        height: 100%;
    }
    section.show-info div.content h1 {
        margin-top: 1em;
        font-size: 1.2rem;
    }

    section.show-info div.content table {
        width: 100%;
    }

    section.show-info div.content div.inner-content {
        font-size: .9rem;
    }
    section.show-info div.content div.inner-content h2 {
        font-size: 1.1rem;
    }

    /* Collectif */
    section#collectif {
        padding-top: 1em;
    }

    section#collectif section {
        width: 90%;
        padding-bottom: .5em;

    }

    section#collectif section h1.person-name {
        font-size: 1.5em;
    }

    section#collectif section h2.person-role {
        font-size:1.2em;
    }

    section#collectif section h3 {
        font-size: 1.1em;
    }

    section#collectif section .info-placeholder .info-cell {
        width: 100%;
    }

    section#collectif iframe {
        height: 200px;
    }

    /* {{{ blog */

    section.blog-post {
        width: 90%;
        margin-top: 3rem;
    }

    section.blog-post section.single-post {
        margin-bottom: 2.5rem;
    }

    section.blog-post div.post-title h1 {
        font-size: 1.2rem;
    }

    section.blog-post div.post-content {
        padding-top: 0;
        font-size: .9rem;

    }

    section.blog-post div.post-footer {
        font-size: .8rem;
    }

    section.blog-post div.nav-links {
        font-size: .8rem;
        text-align: center;

    }
    section.blog-post div.nav-links a {
        padding-left: .2rem;
        padding-right: .2rem;
    }

/*     }}} */


    /* {{{ Contact */

    section.contact, section.mentions-legales, section.site-conception, section.archives, section.generic {
        width: 90%;
        margin-top: 3rem;
        font-size: .9rem;

    }

    section.contact h1, section.mentions-legales h1, section.site-conception h1, section.archives h1, section.generic h1 {
        font-size: 1.2rem;
    }

    section.contact a, section.mentions-legales a, section.site-conception a, section.archives a, section.generic a {
        text-decoration: underline;
    }

    section.archives .archive-item .text {
        font-size: 1rem;
    }

    section.contact input {
        font-size: .9rem;
    }

    section.contact textarea {
        font-size: .9rem;
    }

    section.contact input[type=submit] {
        width: 100%;
        text-align: center;
        border: #ddd 1px solid;

    }
    /*  }}} */
}
/* }}} */

/* vim: set fdm=marker: */
